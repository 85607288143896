/*@font-face {
    font-family: Oswald;
    font-size: 72px;
    src: url(Oswald-ExtraLight.ttf);
}
*/
@font-face {
  font-family: 'Oswald';
  font-weight: 400;
  font-style: normal;
  src: url('/fonts/Oswald/fonts/Oswald-regular.eot');
  src: url('/fonts/Oswald/fonts/Oswald-regular.eot?#iefix') format('embedded-opentype'),
       local('Oswald Regular'),
       local('Oswald-regular'),
       url('/fonts/Oswald/fonts/Oswald-regular.woff2') format('woff2'),
       url('/fonts/Oswald/fonts/Oswald-regular.woff') format('woff'),
       url('/fonts/Oswald/fonts/Oswald-regular.ttf') format('truetype'),
       url('/fonts/Oswald/fonts/Oswald-regular.svg#Oswald') format('svg');
}

body {
    font-family: allerregular, arial, verdana;
}

.headerbg {
    /*background-color: #C9E4B9;*/
    position: relative;
    overflow: hidden;
    background-image: url("../img/bg.png");
    background-color: white;
    background-size: initial;
    background-repeat: repeat;
    padding-top: 1em;
    padding-bottom: 1em;
    padding-right: 1em;
    padding-bottom: 0;
}


.LPlogo {
    /*height: auto;
    width: 65%;
    padding: 0;
    margin: 0;*/
}

.contactNum {
    font-weight: 800;
    color: #444;
    text-align: center;
    font-size: .85em;
}

.SNS {
    padding-left: 1em;
}

.SNSlink {
    background-color: white;
    height: 2.5em;
}

.ad {
    height: 10em;
    border-color: grey;
}

.adcontainer {}

.adx {
    background-color: grey;
    height: 5em;
}
.navrow {
    height: 3.5;
    font-family: Oswald;
    /*background-color: #43AC6A;*/
    background-color: #1AB492;
}

.navbg {
    /*background-color: #43AC6A;*/
    background-color: #1AB492;
    font-family: Oswald;
}

.top-bar {
    /*background: #43AC6A !important;*/
    background-color: #1AB492 !important;
    text-align: center;
}

.top-bar-section {
    display: inline-block;
}

.top-bar-section ul li {
    /*background-color: #43AC6A !important;*/
    background-color: #1AB492 !important;
    /*border: solid 0.1em #43AC6A !important;*/
    border: solid 0.1em #1AB492 !important;
    margin-bottom: 0.5em;
}

.top-bar-section ul li .divider {
    /*background-color: #43AC6A !important;*/
    background-color: #1AB492 !important;
}

.top-bar.expanded .title-area {
    /*background: #43AC6A;*/
    background: #1AB492;
    margin-bottom: 0.3em;
}

.top-bar-section li:hover a {
    background: #368a55 !important;
    color: #FFFFFF;
}

.top-bar-section ul li>a.button.lp-nav,
.top-bar-section ul li > a.button.lp-nav {
    background-color: #1AB492;
    border-color: #1AB492;
}

.top-bar-section ul li>a.button.lp-nav:hover,
.top-bar-section ul li > a.button.lp-nav:hover {
    background: #2F4457 !important;
}

.top-bar-section ul li:not(.active)>a.button.lp-nav:hover,
.top-bar-section ul li:not(.active) > a.button.lp-nav:hover {
    opacity: 0.5;
}

.top-bar-section ul li.active>a {
    background: #2F4457;
    cursor: default;
}

.lp-nav-button-group {
    margin-right: 0 !important;
}

.icons {
    font-size: 36px;
}

.button.warning {
    padding-top: 10px;
    padding-bottom: 10px;
}

.button .warning {
    padding-top: 1em;
    padding-bottom: 1em;
}

hr {
    margin: 0.5em 0;
}

h4 {
    color: white;
}

.hotel span {
    background: #D6D6C2;
}

.hotelbg {
    background-color: #FF9147;
}

.hotelrow {
    /*padding-right: 1em;
    padding-left: 1em;*/
}

.hotel {
    margin-top: 1em;
    margin-bottom: 1em;
    /*position: relative;*/
    overflow: hidden;
    background-image: url("../img/lp-pattern.png");
    background-color: #383735;
    background-size: 40%;
    background-position: 18% 43%;
    background-repeat: repeat;
    min-height: 10em;
    padding: 0.5em;
    padding: 10px 25px 10px 25px;
}

.hotelform {}

.title-main {
    text-align: center;
    font-family: Oswald;
    color:white;
    text-transform: uppercase;
    /*font-weight: 600;*/
    font-size: 35px;
}

.title-in {
    position: relative;
    overflow: hidden;
    background-image: url("../img/lp-pattern.png");
    background-color: #3DC2A5;
    background-size: 25%;
    background-repeat: repeat;
    height: auto;
    padding-top: 12px;
    padding-bottom: 10px;
    background-position: 40% 48%;
}

.contents {
    background: white;
    min-height: 700px;
}

.content {
    background: white;
    padding-bottom: 10px;
}

.content-mini {
    min-height: 20em;
    margin-top: 1em;
}

.sidebar {
    background: white;
    min-height: 50%;
    padding-bottom: 1em;
}

.sponsorrow {
    background: white;
}

.sponsor-title {
    background: grey;
    height: 20%;
}

.sponsor {
    height: 10em;
}

.sponsor-mini {
    background: black;
    height: 5em;
}

.footer {
    padding-top: 3em;
    background: white;
    height: 10em;
}

.contents-other {
    background: white;
}

.contents-other-mini {
    background: black;
    height: 150px;
    margin: 10px;
}

.bodybg {
    position: relative;
    overflow: hidden;
    background-image: url("../img/bg.png");
    background-color: white;
    background-size: initial;
    background-repeat: repeat;
}

.modalbg {
    overflow: hidden;
    background-image: url("../img/bg.png");
    background-color: white;
    background-size: initial;
    background-repeat: repeat;
}

.labelModal {
    font-weight: 600;
}

.bodyrow {
    background-color: white;
}

.other {
    background-color: black;
    height: 10em;
}

.size-12 {
    font-size: 12px;
}

.size-14 {
    font-size: 14px;
}

.size-16 {
    font-size: 16px;
}

.size-18 {
    font-size: 18px;
}

.size-21 {
    font-size: 21px;
}

.size-24 {
    font-size: 24px;
}

.size-36 {
    font-size: 36px;
}

.size-48 {
    font-size: 48px;
}

.size-60 {
    font-size: 60px;
}

.size-72 {
    font-size: 72px;
}

.sidebar-event-pattern {
    position: relative;
    overflow: hidden;
    background-image: url("../img/lp-pattern.png");
    background-color: #4EA717;
    background-size: 80%;
    background-repeat: repeat;
}

#datepicker_event .ui-datepicker-prev {
    background-image: url("../img/prev.png");
    /*margin-left: 20px; 10/03/16 */
    margin-left: 0px;
    width: 1.5em !important;
    height: 1.5em !important;
}

#datepicker_event .ui-datepicker-next {
    background-image: url("../img/next.png");
    /*margin-right: 20px; 10/03/16  */
    margin-left: 0px;
    right: 0px;
    width: 1.5em !important;
    height: 1.5em !important;
}

.sidebar-search {
    position: relative;
    overflow: hidden;
    background-image: url("../img/brown_pattern.png");
    background-color: #26A65B;
    background-size: initial;
    background-repeat: repeat;
    margin-top: 1em;
    padding: 0.5em;
    padding-bottom: 0em;
}

.sidebar-travel {
    position: relative;
    overflow: hidden;
    background-image: url("../img/orange_pattern.png");
    background-color: #26A65B;
    background-size: initial;
    background-repeat: repeat;
    margin-top: 1em;
    min-height: 3em;
}

.sidebar-recent {
    position: relative;
    overflow: hidden;
    background-image: url("../img/lp-pattern.png");
    background-color: #25B0D1;
    background-size: 60%;
    background-repeat: repeat;
    margin-top: 1em;
    min-height: 10em;
    background-position: 100% 19%;
}

.sidebar-recent h6 {
    font-family: Oswald;
}

.sidebar-recent h6.white {
    font-size: 1.2em;
    line-height: 2em;
}

.visayas {
    position: relative;
    overflow: hidden;
    background-image: url("../img/vi.png");
    background-color: #FFB61E;
    background-size: cover;
    background-repeat: repeat;
    margin-top: 1em;
    padding: 10px;
}

.mindanao {
    position: relative;
    overflow: hidden;
    background-image: url("../img/min.png");
    background-color: #DC3023;
    background-size: cover;
    background-repeat: repeat;
    margin-top: 1em;
    padding: 10px;
}

.luzon {
    position: relative;
    overflow: hidden;
    background-image: url("../img/luz.png");
    background-color: #2ABB9B;
    background-size: cover;
    background-repeat: repeat;
    margin-top: 1em;
    padding: 10px;
}

.sidebar-luzon {
    position: relative;
    overflow: hidden;
    background-image: url("../img/luz.png");
    background-color: #2ABB9B;
    background-size: cover;
    background-repeat: repeat;
    margin-top: 1em;
}

.sidebar-visayas {
    position: relative;
    overflow: hidden;
    background-image: url("../img/vi.png");
    background-color: #2ABB9B;
    background-size: cover;
    background-repeat: repeat;
    margin-top: 1em;
}

.sidebar-mindanao {
    position: relative;
    overflow: hidden;
    background-image: url("../img/min.png");
    background-color: #2ABB9B;
    background-size: cover;
    background-repeat: repeat;
    margin-top: 1em;
}

.pattern_design {
    position: relative;
    overflow: hidden;
    background-image: url("../img/black_pattern.png");
    background-color: #2ABB9B;
    background-size: cover;
    background-repeat: repeat;
    margin-top: 1em;
    font-family: Oswald;
    color: white;
    text-transform: uppercase;
    text-align: center;
    padding: 10px;
    font-size: 22px;
}

.travelinfotitle1,
.travelinfotitle1 h3 {
    text-align: center;
    font-family: Oswald;
    color: white;
    position: relative;
    overflow: hidden;
    background-image: url('../img/travel1.png');
    background-size: initial;
    background-repeat: repeat;
    text-transform: uppercase;
}

.travelinfotitle2,
.travelinfotitle2 h3 {
    text-align: center;
    font-family: Oswald;
    color: white;
    position: relative;
    overflow: hidden;
    background-image: url('../img/travel2.png');
    background-size: initial;
    background-repeat: repeat;
    text-transform: uppercase;
}

.travelinfotitle3,
.travelinfotitle3 h3 {
    text-align: center;
    font-family: Oswald;
    color: white;
    position: relative;
    overflow: hidden;
    background-image: url('../img/travel3.png');
    background-size: initial;
    background-repeat: repeat;
    text-transform: uppercase;
}

.travelinfotitle4,
.travelinfotitle4 h3 {
    text-align: center;
    font-family: Oswald;
    color: white;
    position: relative;
    overflow: hidden;
    text-transform: uppercase;
    background-image: url('../img/travel4.png');
    background-size: initial;
    background-repeat: repeat;
}

.travelinfotitle5,
.travelinfotitle5 h3 {
    text-align: center;
    font-family: Oswald;
    color: white;
    position: relative;
    overflow: hidden;
    text-transform: uppercase;
    background-image: url('../img/travel5.png');
    background-size: initial;
    background-repeat: repeat;
}

.adventures {
    background-image: url('../img/blue.jpg');
}

.more_events {
    background-image: url('../img/yellow.jpg');
}

.what_happening {
    background-image: url('../img/violet.jpg');
}

.extra_travelpack {
    background-image: url('../img/luz.png');
}

.hvr-grow {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    color: white;
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
}

.hvr-grow:hover,
.hvr-grow:focus,
.hvr-grow:active {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    color: white;
}

.hvr-skew {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
}

.hvr-skew:hover,
.hvr-skew:focus,
.hvr-skew:active {
    -webkit-transform: skew(-10deg);
    transform: skew(-10deg);
}

.hvr-skew-forward {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transform-origin: 0 100%;
    transform-origin: 0 100%;
}

.hvr-skew-forward:hover,
.hvr-skew-forward:focus,
.hvr-skew-forward:active {
    -webkit-transform: skew(-10deg);
    transform: skew(-10deg);
    color: white;
}

.gray {
  color: #bdbdbd;
}

.lightblue {
  color: #43B492;
}

.spacer {
  margin: 15px;
}

.spacer-top {
    margin-top: 15px;
}

.spacer-bottom {
    margin-bottom: 15px;
}

.spacer-top-25 {
    margin-top: 25px;
}

.spacer-bottom-25 {
    margin-bottom: 25px;
}

.breadcrumbs {
  margin: 1.5em 0 1em 0;
}

.recents-header{
    font-family: Oswald;
    text-transform: uppercase;
    padding-left: 15px;
    padding-bottom: 4px;
}

.recents {
    background-color: white;
}

.recent-row-header {
    font-family: Oswald;
    text-transform: uppercase;
    color: black;
}

.recent-row-header:hover {
    color: #069;
}

.recent-row-plain{
    text-align: justify;
}

.recents-content > .row{
}

.recent-read-more {
    font-style: italic;
    font-weight: bold;
    color: #D35400;
    font-size: 13px;
}
.recent-read-more:hover {
    color: #cf6e0e;
    text-decoration: underline;
}

.recents-hr {
    border: 0;
    border-bottom: 2px dotted #cccccc;
    margin-right: 15px;
    margin-left: 15px;
}
.recents-content-hr {
    border: 0;
    border-bottom: 2px dotted #cccccc;
    margin-right: 0;
    margin-left: 0;
}

.recents-added-title {
    color: #fff;
    /*font-weight: bold;*/
}

.recents-added-title:hover {
    /*color: #0066A5;*/
    text-decoration: underline;
}

.borderless-vcard {
    border: none !important;
    padding-left: 0 !important;
    padding-bottom: 0 !important;
}


#_time, #_date {
    font-family: Oswald;
    text-transform: uppercase;
    color: black;
    font-weight: bold;
}

.SNS ul.SNS-ul li{
    line-height: 0.5;
}

.center-text{
    text-align: center;
}

.center-header-text{
    text-align: center;
    font-family: Oswald;
}

.header-text{
    font-family: Oswald;
    margin-bottom: 30px;
}

.white{
    color: white;
}

.black{
    color: black;
}

.result{
    height: auto;
    background-color: #DFDFDF;
    padding: 8px 2px 8px 2px;
}

.result-snippet{
    font-size: 14px;
    height: 3em;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
}

.result-title, .result-title:hover {
    text-decoration: underline;
    color: black;
    font-size: 18px;
}

.readmore, .readmore:hover {
    color: #AD250D;
    font-size: 14px;
}

.result.empty{
    text-align: center;
}

.submit.expand{
    padding-top: 0.4em;
    padding-bottom: 0.4em;
}

.result-details{
    padding: 0;
}
.result-details a{
    color: black;
    font-size: 18px;
    text-decoration: underline;
}

/*profiles*/
#map {
    width: auto;
    height: 350px;
    border-radius: 5px;
}

.pic{
    background-color: #ECF01F;
    height: 20em;
    margin:1em;
    margin-top: 2em;
}

.image-container{
    width: auto;
    min-height: 100px;
    background-color: #DADFE1;
    border: solid #ABB7B7 1px;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 20px;
}
.img-wrap {
    display: inline-block;
    overflow: hidden;
    position: relative;
    width: 98px;
    height: 100px;
    border: solid #6C7A89 1px;
}
.img-wrap .close {
    position: absolute;
    top: 2px;
    right: 2px;
    z-index: 100;
    background-color: #FFF;
    padding: 5px 2px 2px;
    color: #000;
    font-weight: bold;
    cursor: pointer;
    opacity: .2;
    text-align: center;
    font-size: 22px;
    line-height: 10px;
    border-radius: 50%;
}
.img-wrap:hover .close {
    opacity: 1;
}
.edit-image{
    height: 100%;
    width: inherit;
}

/*story profile*/
.profilepage .info{
    margin-top: 30px;
}
.profilepage h4.title{
    color: #000 !important;
}
.snsstoryshare{
    font-size:36px;
}
.profilepage p.postdate{
    font-style: italic;
    font-size: 12px;
}
h2#modalTitle{
    margin-top: 10px;
}
/*admin part styles - general*/

.logout-button{
   position:fixed;
   top:0;
   right:0;
   z-index:99;
}

.admin-login-reveal{
    max-width: 30%;
    display: none;
}

.admin-selector{
    margin-bottom: 0.5em;
    padding-left: 0px;
    padding-right: 0px;
}

/*nav.blade*/
.top-bar{
    height: 3.4rem;
}

.active a{
    background-color: #264348 !important;
}

.top-bar-section ul li>a.button.lp-nav{
    padding: 0.8em;
    font-family: 'Oswald', sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    font-size: .89em;
    height: 40px;
}

/*sidebar*/
.custom-hr-dashed-white{
    border-top: 1px dashed white;
}

.custom-hr-dashed-gray{
    border-top: 1px dashed #C2BFBF;
}

.sidebar-header{
    font-family:Oswald;
    color:white;
    text-transform: uppercase;
    text-align: left;
    padding-top: 13px;
    font-size: 22px;
}

.weight-700{
    font-weight: 700;
}

/*sidebar-datepicker*/
.event_calendar a:hover{
    font-style:italic;
}

.sidebar-event{
    margin-top: 1em;
    min-height: 10em;
    padding-bottom: 1em;
}

#datepicker_event .highlight{
    background-color:#006442;
    /*height:10em;*/
}

#events-day-results {
    color:white;
    font-family: Arial;
}

#events-day-results h5, #events-monthly-results h5{
    font-family: Oswald;
}

#events-day-results a{
    color:white;
    padding:0.1em;
    width:2em;
    border:1em;
}

#events-day-results h5{
    color:white;
}

#events-monthly-results {
    color:white;
    font-family: Arial;
}

#events-monthly-results a{
    color:white;
    padding:0.1em;
    width:2em;
    border:1em;
}

#events-monthly-results h5{
    color:white;
}

#datepicker_event .ui-datepicker{
    width: 100%;
}

#datepicker_event .ui-datepicker, #datepicker_event .ui-datepicker span {
   font-size:1em;
   font: Arial;
   color: white;
}

#datepicker_event .ui-datepicker-header {
    /*padding:2px; 10/03/16 */
    padding:0px;
    color: white;
    line-height: 20px;
    border: none;
}

#datepicker_event .ui-widget-header {
    color: white;
}

#datepicker_event .ui-datepicker-calendar th{
    text-transform: uppercase;
}

#datepicker_event .ui-state-default{
    font-weight: normal;
    color: white;
    border: none;
}

#datepicker_event .ui-state-default :hover{
    color: black;
}

#datepicker_event .ui-widget-content {
    border: none;
    color: #222222;
}

.profilepage .breadcrumbs a{
    line-height: 150%;
}

#datepicker_event .ui-datepicker-calendar, #datepicker_event .ui-datepicker-calendar tr{
}

#datepicker_event .ui-datepicker-calendar tr td,#datepicker_event .ui-datepicker-calendar th {
    border: 4px solid white;
}

#datepicker_event .ui-datepicker-title{
    border: none;
}

/*sidebar-flights*/
.sidebar-flight{
    background: #00AEFF;
    margin-top: 1em;
    min-height: 10em;
}

#datepicker_flight .ui-datepicker{
    width: 100%;
}

#datepicker_flight .ui-datepicker, #datepicker_flight .ui-datepicker span {
   font-size:1em;
   font: Arial;
   color: white;
}

#datepicker_flight .ui-datepicker-header {
    background: #00AEFF;
    padding:2px;
    color: white;
    line-height: 20px;
    border: none;
}

#datepicker_flight .ui-widget-header {
    background: #00AEFF;
    color: white;
}

#datepicker_flight .ui-datepicker-calendar th{
    text-transform: uppercase;
}

#datepicker_flight .ui-state-default, #datepicker_flight .ui-widget-content .ui-state-default, #datepicker_flight .ui-widget-header .ui-state-default {
    background: #00AEFF;
    font-weight: normal;
    color: white;
    border: none;
}

#datepicker_flight .ui-widget-content {
    border: 1px solid #00AEFF;
    background: #00AEFF;
    color: #222222;
}

#datepicker_flight .ui-datepicker-calendar, #datepicker_flight .ui-datepicker-calendar tr{
    background-color: #00AEFF;
}

#datepicker_flight .ui-datepicker-calendar tr td,#datepicker_flight .ui-datepicker-calendar th {
    border: 4px solid white;
}

#datepicker_flight .ui-datepicker-title{
    border: none;
}

#datepicker_flight .ui-datepicker-prev {
}

#datepicker_flight .ui-datepicker-next {
    background-image: url("img/next.jpeg") !important;
}

/*sidebar-places*/
.sidebar-places a, .sidebar-places{
    color:white;
}

.sidebar-places a:hover{
    font-style: italic;
}

.sidebar-places h3{
    font-family:Oswald;
    color:white;
    text-transform:
    uppercase;text-align:
    left;padding-top: 13px;
    font-size: 22px;
}

.sidebar-visayas, .sidebar-mindanao{
    margin-top: 0;
}

/*sidebar-recent*/
h6.white{
    margin-bottom: 2px;
}

.hr-custom{
    margin-top: 4px;
}

.main-content-image{
   height:auto;
   width:120px;
   float: left;
   margin-right: 8px;
   margin-top: 8px;
}

.recent div p{
    font-size: 14px;
    text-align: justify;
    color:white;
    line-height: 1.2;
    margin-bottom: 4px;
}

.recent h6 {
    padding-left: 8px;
}

.recent {
    margin-bottom: 8px;
}

.recent-content{
    padding-left: 8px;
    padding-right: 8px;
}

.recent-content a {
    color: yellow;

    line-height: 1;
}

.recent-content hr{
    margin: 0px;
}
.main-content {
    display: inline-block;
}

/*sidebar-search*/
.search-div{
    z-index: 999;
    position: absolute;
    margin-top: 70px;
    margin-left: 245px;
    display: none;
}

/*hotel*/
.hotel-bg{
    background-image: url('../img/dinagyang1.jpg');
}

.header-uppercase{
    font-family: Oswald;
    text-transform: uppercase;
}

.hotel-search-hr{
    margin: 0.5em 0;
}

.required-field{
    color: red;
    font-weight: bold;
}

.button.hotel-btn{
    font-family: Oswald;
    text-transform: uppercase;
    padding-left: 3em;
    padding-right: 3em;
    margin-bottom: 0;
}
.holder{
    padding: 0;
}

/*sponsor*/
.padding-pnt2em{
    padding: 0.2em;
}

/*about*/
.padding-3em{
    padding: 3em;
}

.padding-1em{
    padding: 1em;
}

.feedback-bg{
    background-color: #F7CA18;
}

.height-10em{
    height: 10em;
}

/*history*/
.margin-top-pnt5em{
    margin-top: 0.5em;
}

/*home*/
.italic-text{
    font-style: italic;
}

.transform-text-none{
    text-transform: none;
}

.padding-pnt5em{
    padding: 0.5em;
}

.width-100{
    width: 100%;
}

.height-5em{
    height: 5em;
}

.margin-top-1em{
    margin-top: 1em;
}

.padding-left-pnt5em{
    padding-left: 0.5em;
}

.featured-event {
    margin-top: 1em;
    margin-bottom: 2.5em;
}

.featured-title h3{
    font-family: Oswald;
    /*min-height:3em;*/
}

.featured-title h3:hover{
    color: #069;
    /*text-decoration: underline;*/
}

.featured-title h5 {
    color: #000000;
    font-family: Oswald;
}

.featured-title h5:hover {
    color: #069;
}

.featured-picture{
    margin: 20px 0 20px 0;
    /*max-height: 20em;
    min-height: 20em;
    overflow: hidden;*/
}

.featured-picture img {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.featured-excerpt{
    /*height: 6em;*/
    text-align: justify;
    width: auto;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    margin-bottom: 1em;
}
.excerpt-read-more {
    color: #f08a24;
    font-style: italic;
    font-weight: bold;
    font-size: 12px;
}
.excerpt-read-more:hover {
    color: #cf6e0e;
    text-decoration: underline;
}

/*home-recently added stories*/
.recents.row{
    min-height: 20em;
}
/*destinations*/
.island-header{
    margin: 0;
    color: white;
    font-family: 'Oswald', sans-serif;
    text-transform: uppercase;
    font-weight: 500;
}


.island-groups .content-pages{
    padding: 1em;
}

.island-groups .content-pages a:hover{
    font-style: italic;
}

/*events*/
.happening-now{
    background-color: orange;
    color: white;
    padding: 1em;
    text-align: center;
    font-family: Oswald;
    font-size: 24px;
    margin-bottom: 1em;
}

/*hotels*/
.hotelresult{
    height: auto;
    background-color: #D8D8D8;
    /*padding: 1em;*/
}
.hoteldetails{
    padding: 0;
}
.hotel-info{
    padding: 0.6em !important;
}
.hotel-info a {
    color: #069;
    text-decoration: none;
    font-size: 1em;
    line-height: 1em;
    font-weight: bold;
}
.hotel-info a:hover {
    color: #069;
    text-decoration: underline;
}
.hotel-rating{
    cursor: default !important;
}
.hotel-address{
    font-size: 12px !important;
}
.hotel-thumb img {
    height: 100%;
}
.hoteldate{
    color: #D35400;
}
.hotellocation{
}
.hotelsnippet{
    font-size: 14px;
    width: auto;
    height: 3em;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
}
.hotel-thumb{
    padding-left: 0 !important;
}

/*search results*/
.search-results{
    position: absolute;
    z-index: 99;
    background-color: white;
    min-width: 80%;
    max-width: 80%;
    max-height: 40em;
    overflow: scroll;
}

.custom-hr-margin-none{
    margin: 0px;
}

.search-results-result{
    margin: auto;
    border: solid 1px;
    border-top: none;
    border-color: #877749;
}

.search-result-list{
    padding: 10px;
}

.search-result-list a{
    color: #008CBA;
}

.search-result-list:hover{
    background-color: #F5D76E;
    color: #877749;
}

.search-result-category{
    font-family: Oswald;
    color: white;
    background: #877749;
    padding: 10px;
}

/*sitemap*/
.site-page a{
    font-size: 14px;
}

.site-page ul{
    list-style-position:inside;
    /*list-style-type: none;*/
}

.site-page ul li:hover {
    text-decoration: underline;
}

.sitemap-ul-title {
    font-weight: bold;
}

.sitemap-ul-title:hover {
    text-decoration: underline;
}

/*telecom, transpo*/
.justify-text{
    text-align: justify;
}

.font-weight-normal{
    font-weight: normal;
}

/*travel info*/
.white-bg{
    background-color: white;
}

/*travel stories*/
.custom-hr-blue {
    border-width: 1px;
    border-color: #43B492;
}
.stories-title {
    color: #069;
    text-decoration: none;
    font-size: 1.5em !important;
    line-height: 1em;
    font-weight: bold;
}
.stories-title a:hover{
    text-decoration: underline;
}

.storylist{
    max-height: 70em;
}
.storydate{
    font-size: 11px;
}
.storyresult{
    height: auto;
    /*background-color: #D8D8D8;*/
    padding: 0 0 0 1em;
}
.storysnippet{
    font-size: 14px;
        /*width: auto;
        height: 3em;
        white-space: normal;
        overflow: hidden;
        text-overflow: ellipsis;*/
    }

/*
* For Header Google ads
*/
.adcontainer {

}

.header-ads {
    height: auto;
    width: auto;
    overflow: hidden;
}

.wrapperCenter {
    display: table;
    width: 100%;
    height: 100%;
}

.wrapperCenterInner {
    display: table-cell;
    vertical-align: middle;
    height: 100%;
}

.padding-0 {
    padding: 0 !important;
}

.margin-0 {
    margin: 0 !important;
}

a.webicon {
    margin-bottom: 0px;
}

a.webicon:hover {
    margin: 0px;
}

.html-code {
    resize:none;
}

.contact-banner{
    text-align: center;
}

.loginbg {
    background-image: url("../img/bg.png");
    background-color: white;
    background-size: initial;
    background-repeat: repeat;
    margin-top: 40px;
    text-align: center;
}

.loginbg .title-main {
    font-size: 28px;
}

.login-content {
    width: 500px;
    margin-left: auto;
    margin-right: auto;
    font-family: Oswald;
    font-weight: bolder;
    font-size: 20px;
}

.login-content .login-form{
    padding-top: 8px;
}

.login-form input{
    text-align: center;
    margin-bottom: 8px;
    font-size: 20px;
}

.login-button {
    text-transform: uppercase;
    font-family: Oswald !important;
    font-weight: bolder;
}

form#findhotel, .hotelrow .hotel, .hotel .row div {
    position: static;
}

#hotel-city-container {
    position: absolute; 
    z-index: 999; 
    width: 400px;
    margin-top: -15px; 
    margin-left: 0px; 
    overflow-x: hidden; 

    
}

#hotel-city-container .suggestion-contents {
    max-height: 400px;
    padding-top: 0px;
    padding-bottom: 2px;
    background-color: #FFFFFF; 
    border-color: rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    border-width: 1px;
    border-style: solid;
    overflow-y: auto; 
    box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 10px 0px;
    font-size: 12px;
}

.hotel-suggestion-row .hotel-suggestion-column{
    display: inline-block;
    vertical-align: middle;
}

.hotel-suggestion-row:hover {
    background-color: #2F4457 ;
    color: #fff;
    cursor: pointer;
}

.row-province {
    font-style: italic;
    color: #aaaaaa;
    font-size: 12px;
}

.row-city {
    font-size: 14px;
    overflow-x: hidden; 
}

.hotel-suggestion-column:first-child {
    margin-right: 4px;
    margin-left: 4px;
}

/*
* All media queries here
*/

@media only screen and (min-width: 641px) {

    /*extra*/
    .extra-bg{
        background-color: rgba(255,255,255,0.7);
        padding: 1em;
    }

    .extra-info{
        padding:2em;
    }
    .extra-info div{
        height: 20em;
        padding:0;;
        overflow:hidden;
    }
    .extra-info h3{
        margin-top:4em;
    }
    .hov_pic img{
        height:15em;
        width: 100%;
    }
    .hov_pic{
        margin:0;
        position: relative;
        bottom: -1000px;
        transition: all 0.5s ease;
    }
    .extra-info div:hover .hov_pic{
        bottom: 0;
        z-index:99;
        position: absolute;
    }
    .extra-info div:hover .h3{
        top: 0;
    }

}

/*sidebar-datepicker*/
@media only screen and (max-width: 925px){
    #datepicker_event .ui-state-default{
        font-size: 12px;
    }
    #datepicker_event .ui-datepicker-calendar tr td,#datepicker_event .ui-datepicker-calendar th {
        border: 1px solid white;
    }

    #datepicker_event .ui-datepicker-calendar th{
        font-size: 12px;
        text-transform: uppercase;
    }
    #datepicker_event .ui-widget-header {
        font-size: 12px
    }
}


@media only screen and (max-width: 800px){
    #datepicker_event .ui-state-default{
        font-size: 11px;
    }
    #datepicker_event .ui-datepicker-calendar tr td,#datepicker_event .ui-datepicker-calendar th {
        border: 1px solid white;
    }

    #datepicker_event .ui-datepicker-calendar th{
        font-size: 11px;
        text-transform: uppercase;
    }
    #datepicker_event .ui-widget-header {
        font-size: 11px
    }
    .ui-datepicker-title{
        font-size: 11px;
    }
    #datepicker_event .ui-datepicker-prev {
        margin-left: 10px;
    }
    #datepicker_event .ui-datepicker-next {
        margin-right: 10px;
    }
}


@media only screen and (min-width: 640px) and (max-width: 799px){zz
    #datepicker_event .ui-state-default{
        font-size: 10px;
    }
    #datepicker_event .ui-datepicker-calendar tr td,#datepicker_event .ui-datepicker-calendar th {
        border: 1px solid white;
    }

    #datepicker_event .ui-datepicker-calendar th{
        font-size: 9px;
        text-transform: uppercase;
    }
    #datepicker_event .ui-widget-header {
        font-size: 10px
    }
    .ui-datepicker-title{
        font-size: 10px;
    }
    #datepicker_event .ui-datepicker-prev {
        margin-left: 10px;
    }
    #datepicker_event .ui-datepicker-next {
        margin-right: 10px;
    }
}

/*sidebar-flights*/
@media only screen and (max-width: 925px){
    #datepicker_flight .ui-state-default{
        font-size: 12px;

    }
    #datepicker_flight .ui-datepicker-calendar tr td,#datepicker_flight .ui-datepicker-calendar th {
        border: 1px solid white;
    }

    #datepicker_flight .ui-datepicker-calendar th{
        font-size: 12px;
        text-transform: uppercase;
    }
    #datepicker_flight .ui-widget-header {
        font-size: 12px
    }
}

@media only screen and (max-width: 868px) {
    .top-bar {
        /*background-color: #43AC6A;*/
        background-color: #1AB492;
    }
    .top-bar-section ul li > a {
        text-align: left;
    }
}

@media only screen and (max-width: 875px) {
    .contactNum {
        font-weight: 800;
        color: #444;
        text-align: center;
        font-size: .55em;
    }
}

@media only screen and (min-width: 40.063em) {
    .result-info{
        text-align: right;
    }
}

/*nav.blade*/
@media only screen and (min-width: 741px) and (max-width: 866px) {
    .top-bar-section ul li>a.button.lp-nav{
        font-size: .75em;
    }
}

@media only screen and (min-width: 671px) and (max-width: 740px) {
    .top-bar-section ul li>a.button.lp-nav{
        font-size: .67em;
    }
}

@media only screen and (min-width: 641px) and (max-width: 670px) {
    .top-bar-section ul li>a.button.lp-nav{
        font-size: .64em;
    }
}



/*extras*/
/*@media only screen and (min-width: 375px) and (max-width: 866px) {
    .extra-info div{
        height:5em;
    }
    .extra-info{
        padding: 0;
    }
    .extra-info h3{
        margin-top: 1em;
    }
    .hov_pic{
        margin:0;
        position: relative;
        bottom: -1000px;
    }
    .extra-info div:hover .hov_pic{
    }
}*/

/*hotel*/

@media only screen and (max-width: 640px) {

} /* max-width 640px, mobile-only styles, use when QAing mobile issues */

    @media only screen and (min-width: 641px) and (max-width: 1024px) {
        .button.hotel-btn{
            font-family: Oswald;
            text-transform: uppercase;
            margin-bottom: 0;
            font-size: 14px;
        }
    }



/**
* default Foundation 5 media queries
*/
@media only screen { 

} /* Define mobile styles */

/* min-width 641px, medium screens */
@media only screen and (min-width: 40.063em) {
    .header-ads{
        min-width: 728px !important;
        min-height: 90px !important;
    }
}

@media only screen and (min-width: 40.063em) and (max-width: 64em) { } /* min-width 641px and max-width 1024px, use when QAing tablet-only issues */

/* min-width 1025px, large screens */
@media only screen and (min-width: 64.063em) {
    .header-ads{
        min-width: 728px !important;
        min-height: 90px !important;
    }
}

@media only screen and (min-width: 64.063em) and (max-width: 90em) { } /* min-width 1025px and max-width 1440px, use when QAing large screen-only issues */

@media only screen and (min-width: 90.063em) { } /* min-width 1441px, xlarge screens */

@media only screen and (min-width: 90.063em) and (max-width: 120em) { } /* min-width 1441px and max-width 1920px, use when QAing xlarge screen-only issues */

@media only screen and (min-width: 120.063em) { } /* min-width 1921px, xxlarge screens */


/*mobile*/
@media only screen and (max-width: 640px) {
    .hotel, .title-in {
        background-size: 100% !important;
    }
    .travel-info-widget{
        text-align: center;
    }

    .content-mini.travel-story, .content-mini.travel-story > .columns{
        padding-left: 0;
        padding-right: 0;
    }

    .storysnippet iframe{
        width: 400px;
        height: 248px;
    }

    .prefix{
        font-size: 12px;
    }

    .button.hotel-btn{
        font-family: Oswald;
        text-transform: uppercase;
        margin-bottom: 0;
        font-size: 12px;
    }

    .ui-datepicker table {
        width: 50%;
    }

    #datepicker_event .ui-datepicker-calendar {
        margin: auto;
    }

    #datepicker_event .ui-state-default{
        font-size: 11px;
    }

    #datepicker_event .ui-datepicker-calendar tr td,#datepicker_event .ui-datepicker-calendar th {
        border: 3px solid white;
    }

    #datepicker_event .ui-datepicker-calendar th{
        font-size: 10px;
        text-transform: uppercase;
    }

    #datepicker_event .ui-widget-header {
        font-size: 12px
    }

    .ui-datepicker-title{
        font-size: 12px;
    }

    #datepicker_event .ui-datepicker-prev {
        margin-left: 200px;
    }
    #datepicker_event .ui-datepicker-next {
        margin-right: 200px;
    }
    /**/

    .extra-bg > .center-header-text {
        color: whitesmoke;
        margin-bottom: 8px;
        margin-top: 8px;
    }

    .adventures, .more_events {
        margin-bottom: 8px;
    }
}

/*for BlackBerry Playbook-port, Nexus7-port*/
@media only screen and (max-width: 600px) {
    .storysnippet iframe{
        width: 360px;
        height: 223px;
    }

    .storyresult{
        padding: 0;
    }

    .main-content-image {
        margin-top: 2px;
    }

    .recent-content a {
        line-height: 1.5;
        display: block;
    }

    .recents-added-title {
        margin-bottom: 4px;
    }

    .recents-hr {
        margin-top: 4px;
    }

    .recents-header {
        margin-bottom: 4px;
        margin-top: 8px;
    }

    .recent-row-plain {
        margin-bottom: 12px;
    }

    .row-image {
        text-align: center;
    }

    .recent-row-header {
        text-align: center;
    }

    .profilepage .info, .profilepage .info > .columns {
        padding: 0px;
    }


}

/*for iPhone5-land, Nexus 4-land, Nexus 5-land*/
@media only screen and (max-width: 569px) {
    .storysnippet iframe{
        width: 360px;
        height: 223px;
    }

    .sidebar.mobile-content{
        padding-right: 4px;
        padding-left: 4px;
    }

    .island-header {
        margin-left: 5px;
    }

    .content.mobile-content{
        padding-left: 8px;
        padding-right: 8px;
    }

    .content.mobile-content > .content-mini{
        padding-left: 4px;
        padding-right: 4px;
    }

    .island-groups > .content-pages{
        padding-left: 30px;
        padding-right: 30px;
    }
}

/*for Lumia 520-land*/
@media only screen and (max-width: 533px) {
    .storysnippet iframe{
        width: 360px;
        height: 223px;
    }

    #datepicker_event .ui-datepicker-prev {
        margin-left: 160px;
    }
    #datepicker_event .ui-datepicker-next {
        margin-right: 160px;
    }
}

/*for iPhone4-land*/
@media only screen and (max-width: 480px) {
    .storysnippet iframe{
        width: 360px;
        height: 223px;

        margin-left: auto;
        margin-right: auto;
    }

    .spacer {
        margin: 8px;
    }

    .profile-header {
        padding-right: 0px;
        padding-left: 0px;
    }

    .info iframe {
        width: 434px;
        height: 269px;
    }

    .ui-datepicker table {
        width: 60%;
    }

    #datepicker_event .ui-datepicker-prev {
        margin-left: 136px;
    }
    #datepicker_event .ui-datepicker-next {
        margin-right: 140px;
    }
}

/*for iPhone6s-land, Nexus6-port*/
@media only screen and (max-width: 415px) {
    .storysnippet iframe{
        width: 360px;
        height: 223px;
    }

    .event_description {
        padding-right: 0px;
        padding-left: 0px;
    }

    .pattern_design {
        margin-left: -8px;
        margin-right: -8px;
    }

    .info iframe {
        width: 368px;
        height: 228px;
    }

    .ui-datepicker table {
        width: 80%;
    }

    #datepicker_event .ui-datepicker-prev {
        margin-left: 96px;
    }
    #datepicker_event .ui-datepicker-next {
        margin-right: 100px;
    }
}

/*for Nexus4-port, LG Optimus L70-port*/
@media only screen and (max-width: 384px) {
    .storysnippet iframe{
        width: 329px;
        height:204px;
    }

    .info iframe {
        width: 360px;
        height: 223px;
    }

    .profilepage {
        padding-left: 4px;
        padding-right: 4px;
    }

    .profilepage > .row {
        margin-right: 0px;
        margin-left: 0px;
    }

    #datepicker_event .ui-datepicker-prev {
        margin-left: 84px;
    }
    #datepicker_event .ui-datepicker-next {
        margin-right: 84px;
    }
}

/*for iPhone6-port*/
@media only screen and (max-width: 375px) {
    .storysnippet iframe{
        width: 320px;
        height: 198px;
    }

    .info iframe {
        width: 351px;
        height: 218px;
    }
}

/*for BlackBerry Z30-port, Nexus5-port, Note3-port, Note2-port, S3-port, S4-port*/
@media only screen and (max-width: 360px) {
    .storysnippet iframe{
        width: 305px;
        height: 189px;
    }

    .info iframe {
        width: 336px;
        height: 208px;
    }

    .island-groups > .content-pages{
        padding-left: 12px;
        padding-right: 12px;
    }

    .ui-datepicker table {
        width: 90%;
    }

    #datepicker_event .ui-datepicker-prev {
        margin-left: 76px;
    }
    #datepicker_event .ui-datepicker-next {
        margin-right: 76px;
    }
}

/*for iPhone4-port, iPhone5-port*/
@media only screen and (max-width: 320px) {
    .storysnippet iframe{
        width: 265px;
        height: 164px;
    }

    .info iframe {
        width: 296px;
        height: 184px;
    }

    .ui-datepicker table {
        width: 100%;
    }

    #datepicker_event .ui-datepicker-prev {
        margin-left: 56px;
    }
    #datepicker_event .ui-datepicker-next {
        margin-right: 56px;
    }
}
/*END mobile media queries*/

/* hotel info */
ul.hotel-amenities {
    list-style-image: url('/img/check.png');
    float: left;
    list-style-type: none;
    margin: 0 !important;
    padding: 10px 0 0 0;
    width: 100% !important;
}

ul.hotel-amenities li {
    display: list-item;
    float: left;
    list-style-position: inside !important;
    /*list-style-position: outside !important;*/
    list-style-type: none !important;
    margin: 0 0 0 10px;
    padding: 0 10px 0 0;
    width: 44%;
    font-size: 12px;
}

ul.hotel-details-list {
    float: left;
    list-style-type: none;
    margin: 0 !important;
    padding: 10px 0 0 0;
    width: 100% !important;
}

ul.hotel-details-list li {
    display: list-item;
    float: left;
    list-style-position: inside !important;
    /*list-style-position: outside !important;*/
    list-style-type: none !important;
    margin: 0 0 0 10px;
    padding: 0 10px 0 0;
    width: 44%;
    font-size: 12px;
}

.hotel-info-border {
    border: 1px solid;
    border-radius: 7px;
    border-color: #d1d1d1;
    margin-bottom: 10px;
    padding: 12px;
}

.hotel-info-title {
    font-size: 15px;
    font-weight: bold;
}

.hotel-info-p {
    font-size: 14px;
}

.pad-left-0 {
    padding-left: 0 !important;
}

.margin-top-10 {
    margin-top: 10px !important;
}
/* end hotel info */