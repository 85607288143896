/* Webicons by Adam Fairhead at Fairhead Creative http://fairheadcreative.com */
.webicon {
  display: inline-block;
  background-size: 100%;
  width: 30px;
  height: 30px;
  text-indent: -999em;
  text-align: left;
  -moz-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  margin-bottom: 5px; }

.webicon:hover {
  margin: -1px 0 1px;
  -moz-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.25); }

.webicon.small {
  width: 20px;
  height: 20px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px; }

.webicon.large {
  width: 48px;
  height: 48px;
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px; }

/* restore webicons accessibility for print */
@media print {
  * {
      background: transparent !important;
      color: #000 !important;
      -moz-box-shadow: none !important;
      -webkit-box-shadow: none !important;
      box-shadow: none !important;
      text-shadow: none !important;
  }
  .webicon {
    text-indent: 0;
    width: auto !important;
    height: auto !important;
    -moz-border-radius: 0 !important;
    -webkit-border-radius: 0 !important;
    border-radius: 0 !important;
  }
}

.no-svg .webicon.f500px {
  background: url("webicons/webicon-f500px-m.png"); }

.no-svg .webicon.f500px.large {
  background: url("webicons/webicon-f500px.png"); }

.no-svg .webicon.f500px.small {
  background: url("webicons/webicon-f500px-s.png"); }

.svg .webicon.f500px {
  background: url("webicons/webicon-f500px.svg"); }

.no-svg .webicon.aboutme {
  background: url("webicons/webicon-aboutme-m.png"); }

.no-svg .webicon.aboutme.large {
  background: url("webicons/webicon-aboutme.png"); }

.no-svg .webicon.aboutme.small {
  background: url("webicons/webicon-aboutme-s.png"); }

.svg .webicon.aboutme {
  background: url("webicons/webicon-aboutme.svg"); }

.no-svg .webicon.adn {
  background: url("webicons/webicon-adn-m.png"); }

.no-svg .webicon.adn.large {
  background: url("webicons/webicon-adn.png"); }

.no-svg .webicon.adn.small {
  background: url("webicons/webicon-adn-s.png"); }

.svg .webicon.adn {
  background: url("webicons/webicon-adn.svg"); }

.no-svg .webicon.android {
  background: url("webicons/webicon-android-m.png"); }

.no-svg .webicon.android.large {
  background: url("webicons/webicon-android.png"); }

.no-svg .webicon.android.small {
  background: url("webicons/webicon-android-s.png"); }

.svg .webicon.android {
  background: url("webicons/webicon-android.svg"); }

.no-svg .webicon.apple {
  background: url("webicons/webicon-apple-m.png"); }

.no-svg .webicon.apple.large {
  background: url("webicons/webicon-apple.png"); }

.no-svg .webicon.apple.small {
  background: url("webicons/webicon-apple-s.png"); }

.svg .webicon.apple {
  background: url("webicons/webicon-apple.svg"); }

.no-svg .webicon.behance {
  background: url("webicons/webicon-behance-m.png"); }

.no-svg .webicon.behance.large {
  background: url("webicons/webicon-behance.png"); }

.no-svg .webicon.behance.small {
  background: url("webicons/webicon-behance-s.png"); }

.svg .webicon.behance {
  background: url("webicons/webicon-behance.svg"); }

.no-svg .webicon.bitbucket {
  background: url("webicons/webicon-bitbucket-m.png"); }

.no-svg .webicon.bitbucket.large {
  background: url("webicons/webicon-bitbucket.png"); }

.no-svg .webicon.bitbucket.small {
  background: url("webicons/webicon-bitbucket-s.png"); }

.svg .webicon.bitbucket {
  background: url("webicons/webicon-bitbucket.svg"); }

.no-svg .webicon.blogger {
  background: url("webicons/webicon-blogger-m.png"); }

.no-svg .webicon.blogger.large {
  background: url("webicons/webicon-blogger.png"); }

.no-svg .webicon.blogger.small {
  background: url("webicons/webicon-blogger-s.png"); }

.svg .webicon.blogger {
  background: url("webicons/webicon-blogger.svg"); }

.no-svg .webicon.branch {
  background: url("webicons/webicon-branch-m.png"); }

.no-svg .webicon.branch.large {
  background: url("webicons/webicon-branch.png"); }

.no-svg .webicon.branch.small {
  background: url("webicons/webicon-branch-s.png"); }

.svg .webicon.branch {
  background: url("webicons/webicon-branch.svg"); }

.no-svg .webicon.coderwall {
  background: url("webicons/webicon-coderwall-m.png"); }

.no-svg .webicon.coderwall.large {
  background: url("webicons/webicon-coderwall.png"); }

.no-svg .webicon.coderwall.small {
  background: url("webicons/webicon-coderwall-s.png"); }

.svg .webicon.coderwall {
  background: url("webicons/webicon-coderwall.svg"); }

.no-svg .webicon.creativecloud {
  background: url("webicons/webicon-creativecloud-m.png"); }

.no-svg .webicon.creativecloud.large {
  background: url("webicons/webicon-creativecloud.png"); }

.no-svg .webicon.creativecloud.small {
  background: url("webicons/webicon-creativecloud-s.png"); }

.svg .webicon.creativecloud {
  background: url("webicons/webicon-creativecloud.svg"); }

.no-svg .webicon.dribbble {
  background: url("webicons/webicon-dribbble-m.png"); }

.no-svg .webicon.dribbble.large {
  background: url("webicons/webicon-dribbble.png"); }

.no-svg .webicon.dribbble.small {
  background: url("webicons/webicon-dribbble-s.png"); }

.svg .webicon.dribbble {
  background: url("webicons/webicon-dribbble.svg"); }

.no-svg .webicon.deviantart {
  background: url("webicons/webicon-deviantart-m.png"); }

.no-svg .webicon.deviantart.large {
  background: url("webicons/webicon-deviantart.png"); }

.no-svg .webicon.deviantart.small {
  background: url("webicons/webicon-deviantart-s.png"); }

.svg .webicon.deviantart {
  background: url("webicons/webicon-deviantart.svg"); }

.no-svg .webicon.dropbox {
  background: url("webicons/webicon-dropbox-m.png"); }

.no-svg .webicon.dropbox.large {
  background: url("webicons/webicon-dropbox.png"); }

.no-svg .webicon.dropbox.small {
  background: url("webicons/webicon-dropbox-s.png"); }

.svg .webicon.dropbox {
  background: url("webicons/webicon-dropbox.svg"); }

.no-svg .webicon.evernote {
  background: url("webicons/webicon-evernote-m.png"); }

.no-svg .webicon.evernote.large {
  background: url("webicons/webicon-evernote.png"); }

.no-svg .webicon.evernote.small {
  background: url("webicons/webicon-evernote-s.png"); }

.svg .webicon.evernote {
  background: url("webicons/webicon-evernote.svg"); }

.no-svg .webicon.fairheadcreative {
  background: url("webicons/webicon-fairheadcreative-m.png"); }

.no-svg .webicon.fairheadcreative.large {
  background: url("webicons/webicon-fairheadcreative.png"); }

.no-svg .webicon.fairheadcreative.small {
  background: url("webicons/webicon-fairheadcreative-s.png"); }

.svg .webicon.fairheadcreative {
  background: url("webicons/webicon-fairheadcreative.svg"); }

.no-svg .webicon.facebook {
  background: url("webicons/webicon-facebook-m.png"); }

.no-svg .webicon.facebook.large {
  background: url("webicons/webicon-facebook.png"); }

.no-svg .webicon.facebook.small {
  background: url("webicons/webicon-facebook-s.png"); }

.svg .webicon.facebook {
  background: url("webicons/webicon-facebook.svg"); }

.no-svg .webicon.flickr {
  background: url("webicons/webicon-flickr-m.png"); }

.no-svg .webicon.flickr.large {
  background: url("webicons/webicon-flickr.png"); }

.no-svg .webicon.flickr.small {
  background: url("webicons/webicon-flickr-s.png"); }

.svg .webicon.flickr {
  background: url("webicons/webicon-flickr.svg"); }

.no-svg .webicon.foursquare {
  background: url("webicons/webicon-foursquare-m.png"); }

.no-svg .webicon.foursquare.large {
  background: url("webicons/webicon-foursquare.png"); }

.no-svg .webicon.foursquare.small {
  background: url("webicons/webicon-foursquare-s.png"); }

.svg .webicon.foursquare {
  background: url("webicons/webicon-foursquare.svg"); }

.no-svg .webicon.git {
  background: url("webicons/webicon-git-m.png"); }

.no-svg .webicon.git.large {
  background: url("webicons/webicon-git.png"); }

.no-svg .webicon.git.small {
  background: url("webicons/webicon-git-s.png"); }

.svg .webicon.git {
  background: url("webicons/webicon-git.svg"); }

.no-svg .webicon.github {
  background: url("webicons/webicon-github-m.png"); }

.no-svg .webicon.github.large {
  background: url("webicons/webicon-github.png"); }

.no-svg .webicon.github.small {
  background: url("webicons/webicon-github-s.png"); }

.svg .webicon.github {
  background: url("webicons/webicon-github.svg"); }

.no-svg .webicon.goodreads {
  background: url("webicons/webicon-goodreads-m.png"); }

.no-svg .webicon.goodreads.large {
  background: url("webicons/webicon-goodreads.png"); }

.no-svg .webicon.goodreads.small {
  background: url("webicons/webicon-goodreads-s.png"); }

.svg .webicon.goodreads {
  background: url("webicons/webicon-goodreads.svg"); }

.no-svg .webicon.google {
  background: url("webicons/webicon-google-m.png"); }

.no-svg .webicon.google.large {
  background: url("webicons/webicon-google.png"); }

.no-svg .webicon.google.small {
  background: url("webicons/webicon-google-s.png"); }

.svg .webicon.google {
  background: url("webicons/webicon-google.svg"); }

.no-svg .webicon.googleplay {
  background: url("webicons/webicon-googleplay-m.png"); }

.no-svg .webicon.googleplay.large {
  background: url("webicons/webicon-googleplay.png"); }

.no-svg .webicon.googleplay.small {
  background: url("webicons/webicon-googleplay-s.png"); }

.svg .webicon.googleplay {
  background: url("webicons/webicon-googleplay.svg"); }

.no-svg .webicon.googleplus {
  background: url("webicons/webicon-googleplus-m.png"); }

.no-svg .webicon.googleplus.large {
  background: url("webicons/webicon-googleplus.png"); }

.no-svg .webicon.googleplus.small {
  background: url("webicons/webicon-googleplus-s.png"); }

.svg .webicon.googleplus {
  background: url("webicons/webicon-googleplus.svg"); }

.no-svg .webicon.hangouts {
  background: url("webicons/webicon-hangouts-m.png"); }

.no-svg .webicon.hangouts.large {
  background: url("webicons/webicon-hangouts.png"); }

.no-svg .webicon.hangouts.small {
  background: url("webicons/webicon-hangouts-s.png"); }

.svg .webicon.hangouts {
  background: url("webicons/webicon-hangouts.svg"); }

.no-svg .webicon.html5 {
  background: url("webicons/webicon-html5-m.png"); }

.no-svg .webicon.html5.large {
  background: url("webicons/webicon-html5.png"); }

.no-svg .webicon.html5.small {
  background: url("webicons/webicon-html5-s.png"); }

.svg .webicon.html5 {
  background: url("webicons/webicon-html5.svg"); }

.no-svg .webicon.icloud {
  background: url("webicons/webicon-icloud-m.png"); }

.no-svg .webicon.icloud.large {
  background: url("webicons/webicon-icloud.png"); }

.no-svg .webicon.icloud.small {
  background: url("webicons/webicon-icloud-s.png"); }

.svg .webicon.icloud {
  background: url("webicons/webicon-icloud.svg"); }

.no-svg .webicon.indiegogo {
  background: url("webicons/webicon-indiegogo-m.png"); }

.no-svg .webicon.indiegogo.large {
  background: url("webicons/webicon-indiegogo.png"); }

.no-svg .webicon.indiegogo.small {
  background: url("webicons/webicon-indiegogo-s.png"); }

.svg .webicon.indiegogo {
  background: url("webicons/webicon-indiegogo.svg"); }

.no-svg .webicon.instagram {
  background: url("webicons/webicon-instagram-m.png"); }

.no-svg .webicon.instagram.large {
  background: url("webicons/webicon-instagram.png"); }

.no-svg .webicon.instagram.small {
  background: url("webicons/webicon-instagram-s.png"); }

.svg .webicon.instagram {
  background: url("webicons/webicon-instagram.svg"); }

.no-svg .webicon.instapaper {
  background: url("webicons/webicon-instapaper-m.png"); }

.no-svg .webicon.instapaper.large {
  background: url("webicons/webicon-instapaper.png"); }

.no-svg .webicon.instapaper.small {
  background: url("webicons/webicon-instapaper-s.png"); }

.svg .webicon.instapaper {
  background: url("webicons/webicon-instapaper.svg"); }

.no-svg .webicon.kickstarter {
  background: url("webicons/webicon-kickstarter-m.png"); }

.no-svg .webicon.kickstarter.large {
  background: url("webicons/webicon-kickstarter.png"); }

.no-svg .webicon.kickstarter.small {
  background: url("webicons/webicon-kickstarter-s.png"); }

.svg .webicon.kickstarter {
  background: url("webicons/webicon-kickstarter.svg"); }

.no-svg .webicon.klout {
  background: url("webicons/webicon-klout-m.png"); }

.no-svg .webicon.klout.large {
  background: url("webicons/webicon-klout.png"); }

.no-svg .webicon.klout.small {
  background: url("webicons/webicon-klout-s.png"); }

.svg .webicon.klout {
  background: url("webicons/webicon-klout.svg"); }

.no-svg .webicon.lastfm {
  background: url("webicons/webicon-lastfm-m.png"); }

.no-svg .webicon.lastfm.large {
  background: url("webicons/webicon-lastfm.png"); }

.no-svg .webicon.lastfm.small {
  background: url("webicons/webicon-lastfm-s.png"); }

.svg .webicon.lastfm {
  background: url("webicons/webicon-lastfm.svg"); }

.no-svg .webicon.linkedin {
  background: url("webicons/webicon-linkedin-m.png"); }

.no-svg .webicon.linkedin.large {
  background: url("webicons/webicon-linkedin.png"); }

.no-svg .webicon.linkedin.small {
  background: url("webicons/webicon-linkedin-s.png"); }

.svg .webicon.linkedin {
  background: url("webicons/webicon-linkedin.svg"); }

.no-svg .webicon.mail {
  background: url("webicons/webicon-mail-m.png"); }

.no-svg .webicon.mail.large {
  background: url("webicons/webicon-mail.png"); }

.no-svg .webicon.mail.small {
  background: url("webicons/webicon-mail-s.png"); }

.svg .webicon.mail {
  background: url("webicons/webicon-mail.svg"); }

.no-svg .webicon.medium {
  background: url("webicons/webicon-medium-m.png"); }

.no-svg .webicon.medium.large {
  background: url("webicons/webicon-medium.png"); }

.no-svg .webicon.medium.small {
  background: url("webicons/webicon-medium-s.png"); }

.svg .webicon.medium {
  background: url("webicons/webicon-medium.svg"); }

.no-svg .webicon.mixi {
  background: url("webicons/webicon-mixi-m.png"); }

.no-svg .webicon.mixi.large {
  background: url("webicons/webicon-mixi.png"); }

.no-svg .webicon.mixi.small {
  background: url("webicons/webicon-mixi-s.png"); }

.svg .webicon.mixi {
  background: url("webicons/webicon-mixi.svg"); }

.no-svg .webicon.msn {
  background: url("webicons/webicon-msn-m.png"); }

.no-svg .webicon.msn.large {
  background: url("webicons/webicon-msn.png"); }

.no-svg .webicon.msn.small {
  background: url("webicons/webicon-msn-s.png"); }

.svg .webicon.msn {
  background: url("webicons/webicon-msn.svg"); }

.no-svg .webicon.openid {
  background: url("webicons/webicon-openid-m.png"); }

.no-svg .webicon.openid.large {
  background: url("webicons/webicon-openid.png"); }

.no-svg .webicon.openid.small {
  background: url("webicons/webicon-openid-s.png"); }

.svg .webicon.openid {
  background: url("webicons/webicon-openid.svg"); }

.no-svg .webicon.picasa {
  background: url("webicons/webicon-picasa-m.png"); }

.no-svg .webicon.picasa.large {
  background: url("webicons/webicon-picasa.png"); }

.no-svg .webicon.picasa.small {
  background: url("webicons/webicon-picasa-s.png"); }

.svg .webicon.picasa {
  background: url("webicons/webicon-picasa.svg"); }

.no-svg .webicon.pinterest {
  background: url("webicons/webicon-pinterest-m.png"); }

.no-svg .webicon.pinterest.large {
  background: url("webicons/webicon-pinterest.png"); }

.no-svg .webicon.pinterest.small {
  background: url("webicons/webicon-pinterest-s.png"); }

.svg .webicon.pinterest {
  background: url("webicons/webicon-pinterest.svg"); }

.no-svg .webicon.pocketapp {
  background: url("webicons/webicon-pocketapp-m.png"); }

.no-svg .webicon.pocketapp.large {
  background: url("webicons/webicon-pocketapp.png"); }

.no-svg .webicon.pocketapp.small {
  background: url("webicons/webicon-pocketapp-s.png"); }

.svg .webicon.pocketapp {
  background: url("webicons/webicon-pocketapp.svg"); }

.no-svg .webicon.potluck {
  background: url("webicons/webicon-potluck-m.png"); }

.no-svg .webicon.potluck.large {
  background: url("webicons/webicon-potluck.png"); }

.no-svg .webicon.potluck.small {
  background: url("webicons/webicon-potluck-s.png"); }

.svg .webicon.potluck {
  background: url("webicons/webicon-potluck.svg"); }

.no-svg .webicon.quora {
  background: url("webicons/webicon-quora-m.png"); }

.no-svg .webicon.quora.large {
  background: url("webicons/webicon-quora.png"); }

.no-svg .webicon.quora.small {
  background: url("webicons/webicon-quora-s.png"); }

.svg .webicon.quora {
  background: url("webicons/webicon-quora.svg"); }

.no-svg .webicon.orkut {
  background: url("webicons/webicon-orkut-m.png"); }

.no-svg .webicon.orkut.large {
  background: url("webicons/webicon-orkut.png"); }

.no-svg .webicon.orkut.small {
  background: url("webicons/webicon-orkut-s.png"); }

.svg .webicon.orkut {
  background: url("webicons/webicon-orkut.svg"); }

.no-svg .webicon.mercurial {
  background: url("webicons/webicon-mercurial-m.png"); }

.no-svg .webicon.mercurial.large {
  background: url("webicons/webicon-mercurial.png"); }

.no-svg .webicon.mercurial.small {
  background: url("webicons/webicon-mercurial-s.png"); }

.svg .webicon.mercurial {
  background: url("webicons/webicon-mercurial.svg"); }

.no-svg .webicon.rdio {
  background: url("webicons/webicon-rdio-m.png"); }

.no-svg .webicon.rdio.large {
  background: url("webicons/webicon-rdio.png"); }

.no-svg .webicon.rdio.small {
  background: url("webicons/webicon-rdio-s.png"); }

.svg .webicon.rdio {
  background: url("webicons/webicon-rdio.svg"); }

.no-svg .webicon.reddit {
  background: url("webicons/webicon-reddit-m.png"); }

.no-svg .webicon.reddit.large {
  background: url("webicons/webicon-reddit.png"); }

.no-svg .webicon.reddit.small {
  background: url("webicons/webicon-reddit-s.png"); }

.svg .webicon.reddit {
  background: url("webicons/webicon-reddit.svg"); }

.no-svg .webicon.renren {
  background: url("webicons/webicon-renren-m.png"); }

.no-svg .webicon.renren.large {
  background: url("webicons/webicon-renren.png"); }

.no-svg .webicon.renren.small {
  background: url("webicons/webicon-renren-s.png"); }

.svg .webicon.renren {
  background: url("webicons/webicon-renren.svg"); }

.no-svg .webicon.rss {
  background: url("webicons/webicon-rss-m.png"); }

.no-svg .webicon.rss.large {
  background: url("webicons/webicon-rss.png"); }

.no-svg .webicon.rss.small {
  background: url("webicons/webicon-rss-s.png"); }

.svg .webicon.rss {
  background: url("webicons/webicon-rss.svg"); }

.no-svg .webicon.skitch {
  background: url("webicons/webicon-skitch-m.png"); }

.no-svg .webicon.skitch.large {
  background: url("webicons/webicon-skitch.png"); }

.no-svg .webicon.skitch.small {
  background: url("webicons/webicon-skitch-s.png"); }

.svg .webicon.skitch {
  background: url("webicons/webicon-skitch.svg"); }

.no-svg .webicon.skype {
  background: url("webicons/webicon-skype-m.png"); }

.no-svg .webicon.skype.large {
  background: url("webicons/webicon-skype.png"); }

.no-svg .webicon.skype.small {
  background: url("webicons/webicon-skype-s.png"); }

.svg .webicon.skype {
  background: url("webicons/webicon-skype.svg"); }

.no-svg .webicon.soundcloud {
  background: url("webicons/webicon-soundcloud-m.png"); }

.no-svg .webicon.soundcloud.large {
  background: url("webicons/webicon-soundcloud.png"); }

.no-svg .webicon.soundcloud.small {
  background: url("webicons/webicon-soundcloud-s.png"); }

.svg .webicon.soundcloud {
  background: url("webicons/webicon-soundcloud.svg"); }

.no-svg .webicon.spotify {
  background: url("webicons/webicon-spotify-m.png"); }

.no-svg .webicon.spotify.large {
  background: url("webicons/webicon-spotify.png"); }

.no-svg .webicon.spotify.small {
  background: url("webicons/webicon-spotify-s.png"); }

.svg .webicon.spotify {
  background: url("webicons/webicon-spotify.svg"); }

.no-svg .webicon.stackoverflow {
  background: url("webicons/webicon-stackoverflow-m.png"); }

.no-svg .webicon.stackoverflow.large {
  background: url("webicons/webicon-stackoverflow.png"); }

.no-svg .webicon.stackoverflow.small {
  background: url("webicons/webicon-stackoverflow-s.png"); }

.svg .webicon.stackoverflow {
  background: url("webicons/webicon-stackoverflow.svg"); }

.no-svg .webicon.stumbleupon {
  background: url("webicons/webicon-stumbleupon-m.png"); }

.no-svg .webicon.stumbleupon.large {
  background: url("webicons/webicon-stumbleupon.png"); }

.no-svg .webicon.stumbleupon.small {
  background: url("webicons/webicon-stumbleupon-s.png"); }

.svg .webicon.stumbleupon {
  background: url("webicons/webicon-stumbleupon.svg"); }

.no-svg .webicon.svtle {
  background: url("webicons/webicon-svtle-m.png"); }

.no-svg .webicon.svtle.large {
  background: url("webicons/webicon-svtle.png"); }

.no-svg .webicon.svtle.small {
  background: url("webicons/webicon-svtle-s.png"); }

.svg .webicon.svtle {
  background: url("webicons/webicon-svtle.svg"); }

.no-svg .webicon.svn {
  background: url("webicons/webicon-svn-m.png"); }

.no-svg .webicon.svn.large {
  background: url("webicons/webicon-svn.png"); }

.no-svg .webicon.svn.small {
  background: url("webicons/webicon-svn-s.png"); }

.svg .webicon.svn {
  background: url("webicons/webicon-svn.svg"); }

.no-svg .webicon.tent {
  background: url("webicons/webicon-tent-m.png"); }

.no-svg .webicon.tent.large {
  background: url("webicons/webicon-tent.png"); }

.no-svg .webicon.tent.small {
  background: url("webicons/webicon-tent-s.png"); }

.svg .webicon.tent {
  background: url("webicons/webicon-tent.svg"); }

.no-svg .webicon.tripadvisor {
  background: url("webicons/webicon-tripadvisor-m.png"); }

.no-svg .webicon.tripadvisor.large {
  background: url("webicons/webicon-tripadvisor.png"); }

.no-svg .webicon.tripadvisor.small {
  background: url("webicons/webicon-tripadvisor-s.png"); }

.svg .webicon.tripadvisor {
  background: url("webicons/webicon-tripadvisor.svg"); }

.no-svg .webicon.tumblr {
  background: url("webicons/webicon-tumblr-m.png"); }

.no-svg .webicon.tumblr.large {
  background: url("webicons/webicon-tumblr.png"); }

.no-svg .webicon.tumblr.small {
  background: url("webicons/webicon-tumblr-s.png"); }

.svg .webicon.tumblr {
  background: url("webicons/webicon-tumblr.svg"); }

.no-svg .webicon.twitter {
  background: url("webicons/webicon-twitter-m.png"); }

.no-svg .webicon.twitter.large {
  background: url("webicons/webicon-twitter.png"); }

.no-svg .webicon.twitter.small {
  background: url("webicons/webicon-twitter-s.png"); }

.svg .webicon.twitter {
  background: url("webicons/webicon-twitter.svg"); }

.no-svg .webicon.viadeo {
  background: url("webicons/webicon-viadeo-m.png"); }

.no-svg .webicon.viadeo.large {
  background: url("webicons/webicon-viadeo.png"); }

.no-svg .webicon.viadeo.small {
  background: url("webicons/webicon-viadeo-s.png"); }

.svg .webicon.viadeo {
  background: url("webicons/webicon-viadeo.svg"); }

.no-svg .webicon.vine {
  background: url("webicons/webicon-vine-m.png"); }

.no-svg .webicon.vine.large {
  background: url("webicons/webicon-vine.png"); }

.no-svg .webicon.vine.small {
  background: url("webicons/webicon-vine-s.png"); }

.svg .webicon.vine {
  background: url("webicons/webicon-vine.svg"); }

.no-svg .webicon.vimeo {
  background: url("webicons/webicon-vimeo-m.png"); }

.no-svg .webicon.vimeo.large {
  background: url("webicons/webicon-vimeo.png"); }

.no-svg .webicon.vimeo.small {
  background: url("webicons/webicon-vimeo-s.png"); }

.svg .webicon.vimeo {
  background: url("webicons/webicon-vimeo.svg"); }

.no-svg .webicon.weibo {
  background: url("webicons/webicon-weibo-m.png"); }

.no-svg .webicon.weibo.large {
  background: url("webicons/webicon-weibo.png"); }

.no-svg .webicon.weibo.small {
  background: url("webicons/webicon-weibo-s.png"); }

.svg .webicon.weibo {
  background: url("webicons/webicon-weibo.svg"); }

.no-svg .webicon.windows {
  background: url("webicons/webicon-windows-m.png"); }

.no-svg .webicon.windows.large {
  background: url("webicons/webicon-windows.png"); }

.no-svg .webicon.windows.small {
  background: url("webicons/webicon-windows-s.png"); }

.svg .webicon.windows {
  background: url("webicons/webicon-windows.svg"); }

.no-svg .webicon.wordpress {
  background: url("webicons/webicon-wordpress-m.png"); }

.no-svg .webicon.wordpress.large {
  background: url("webicons/webicon-wordpress.png"); }

.no-svg .webicon.wordpress.small {
  background: url("webicons/webicon-wordpress-s.png"); }

.svg .webicon.wordpress {
  background: url("webicons/webicon-wordpress.svg"); }

.no-svg .webicon.xing {
  background: url("webicons/webicon-xing-m.png"); }

.no-svg .webicon.xing.large {
  background: url("webicons/webicon-xing.png"); }

.no-svg .webicon.xing.small {
  background: url("webicons/webicon-xing-s.png"); }

.svg .webicon.xing {
  background: url("webicons/webicon-xing.svg"); }

.no-svg .webicon.yahoo {
  background: url("webicons/webicon-yahoo-m.png"); }

.no-svg .webicon.yahoo.large {
  background: url("webicons/webicon-yahoo.png"); }

.no-svg .webicon.yahoo.small {
  background: url("webicons/webicon-yahoo-s.png"); }

.svg .webicon.yahoo {
  background: url("webicons/webicon-yahoo.svg"); }

.no-svg .webicon.yelp {
  background: url("webicons/webicon-yelp-m.png"); }

.no-svg .webicon.yelp.large {
  background: url("webicons/webicon-yelp.png"); }

.no-svg .webicon.yelp.small {
  background: url("webicons/webicon-yelp-s.png"); }

.svg .webicon.yelp {
  background: url("webicons/webicon-yelp.svg"); }

.no-svg .webicon.youtube {
  background: url("webicons/webicon-youtube-m.png"); }

.no-svg .webicon.youtube.large {
  background: url("webicons/webicon-youtube.png"); }

.no-svg .webicon.youtube.small {
  background: url("webicons/webicon-youtube-s.png"); }

.svg .webicon.youtube {
  background: url("webicons/webicon-youtube.svg"); }

.no-svg .webicon.youversion {
  background: url("webicons/webicon-youversion-m.png"); }

.no-svg .webicon.youversion.large {
  background: url("webicons/webicon-youversion.png"); }

.no-svg .webicon.youversion.small {
  background: url("webicons/webicon-youversion-s.png"); }

.svg .webicon.youversion {
  background: url("webicons/webicon-youversion.svg"); }

.no-svg .webicon.zerply {
  background: url("webicons/webicon-zerply-m.png"); }

.no-svg .webicon.zerply.large {
  background: url("webicons/webicon-zerply.png"); }

.no-svg .webicon.zerply.small {
  background: url("webicons/webicon-zerply-s.png"); }

.svg .webicon.zerply {
  background: url("webicons/webicon-zerply.svg"); }
